import { FETCH_CHANNEL, FETCH_CHANNEL_USER } from './actionType'

const initialState = {
  channel: {},
  channelusers:{}
}
const channelReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CHANNEL:
      return {
        ...state,
        channel: action.payload,
      }
      case FETCH_CHANNEL_USER:
        return {
          ...state,
          channelusers: action.payload,
        }
    default:
      return state
  }
}

export default channelReducer
