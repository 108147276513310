import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./indexReducer";
import { FETCH_TOKEN } from "src/views/pages/login/actionType";
import Cookies from "universal-cookie";

const initialState = {
  sidebarShow: true,
};
let data1 = localStorage.getItem("token");
let data2 = JSON.parse(data1);
const cookies = new Cookies();
const persistedState = data2;
const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "set":
      return { ...state, ...rest };
    default:
      return state;
  }
};
const middleware = applyMiddleware(thunk);
const store = createStore(rootReducer, middleware);
store.dispatch({ type: FETCH_TOKEN, payload: persistedState });
export default store;
