import { FETCH_CUSTOMER, FETCH_VENDER } from './actionType'

const initialState = {
  customers: {},
  vender: {},
  // userLocation: DEFAULT_DATA
}
const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CUSTOMER:
      return {
        ...state,
        customers: action.payload,
      }
    case FETCH_VENDER:
        return {
            ...state,
            vender: action.payload
        };
    // case USER_LOCATION:
    //     return {
    //         ...state,
    //         userLocation: action.payload
    //     };
    default:
      return state
  }
}

export default customerReducer
