import { connect } from 'react-redux'
import Logo from '../../assets/images/innocule.webp'
import React, { Component } from 'react'
class Loader extends Component {
  render() {
    return (
      <>
        {this.props.loading == true && (
          <div className="custom-loader">
            <div className="loader-logo">
              <img src={Logo} alt="Logo" />
            </div>
            <div className="loader-circle"></div> {/* Circle element */}
          </div>
        )}
      </>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Loader)
