import { FETCH_CREATE_SITE_USER } from './actionType'

const initialState = {
    siteuser: {},
  // profileData: {},
  // userLocation: DEFAULT_DATA
  
}
const createSiteUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CREATE_SITE_USER:
      return {
        ...state,
        siteuser: action.payload,
      }
     
    // case USER_PROFILE:
    //     return {
    //         ...state,
    //         profileData: action.payload
    //     };
    // case USER_LOCATION:
    //     return {
    //         ...state,
    //         userLocation: action.payload
    //     };
    default:
      return state
  }
}

export default createSiteUserReducer
