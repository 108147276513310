import "react-app-polyfill/stable";
import "core-js";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "../src/redux/store";
import Loader from "./components/common/Loader";
import "./Css/Style.css";
// import store from './store'

createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <Loader />
    <App />
  </Provider>
);
reportWebVitals();
