import { combineReducers } from 'redux'
import store from '../store'
import userReducer from '../views/pages/login/reducer'
import commonReducer from '../components/common/reducer'
import deptReducer from '../../src/views/Department/reducer'
import degReducer from '../../src/views/Designation/reducer'
import empReducer from '../../src/views/Employee/reducer'
import customerReducer from '../../src/views/Customer/reducer'
import siteReducer from '../../src/views/Site/reducer'
import permissionReducer from 'src/views/Permissions/reducer'
import shiftreducer from 'src/views/HRMS/reducer'
import changeshiftReducer from 'src/views/ChangeShift/reducer'
import createSiteUserReducer from 'src/views/CreateSiteUser/reducer'
import shiftReducer from 'src/views/Shift/reducer'
import siteAttendanceReducer from 'src/views/SiteAttendance/reducer'
import alarmReducer from 'src/views/Alaram/reducer'
import channelReducer from "../views/Channel/reducer"
import inventoryReducer from "../views/Inventory/reducer"

const rootReducer = combineReducers({
  store,
  commonReducer,
  userReducer,
  deptReducer,
  degReducer,
  empReducer,
  customerReducer,
  siteReducer,
  permissionReducer,
  shiftreducer,
  shiftReducer,
  permissionReducer,
  changeshiftReducer,
  siteAttendanceReducer,
  createSiteUserReducer,
  alarmReducer,
  channelReducer,
  inventoryReducer
})

export default rootReducer
