import { FETCH_SITE, USER_SITE } from './actionType'

const initialState = {
  site: {},
  usersite:{}
}
const siteReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SITE:
      return {
        ...state,
        site: action.payload,
      }
      case USER_SITE:
      return {
        ...state,
        usersite: action.payload,
      }
    default:
      return state
  }
}

export default siteReducer
