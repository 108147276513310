import { LOADING_END, LOADING_START, SET_NOTIFICATION } from './actionType'

const initialState = {
  loading: false,
  //   formDetails: DEFAULT_DATA,
  notification: {
    show: false,
    text: '',
    usercountsdash: {},
    color: 'white',
  },
  //   last7days: {},
}

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_START:
      return {
        ...state,
        loading: true,
      }
    case LOADING_END:
      return {
        ...state,
        loading: false,
      }
    case SET_NOTIFICATION:
      return {
        ...state,
        notification: action.payload,
      }
    // case FETCH_7DAYS:
    //   return {
    //     ...state,
    //     last7days: action.payload,
    //   }
    // case USER_COUNT:
    //   return {
    //     ...state,
    //     usercountsdash: action.payload,
    //   }
    default:
      return state
  }
}

export default commonReducer
