import { FETCH_ALLROLL,SINGLE_ROLE, FETCH_ALLUSER, FETCH_PAGES, FETCH_PERMISSIONS, FETCH_ROLES, FETCH_USER_PERMISSION } from "./actionType"


const initialState = {
    totaluser: {},
    totalpages:{},
    userpermission:{},
    allpermissions:{},
    roles:{},
    allroles:{},
  
}
const permissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALLUSER:
      return {
        ...state,
        totaluser: action.payload,
      }
      case FETCH_PAGES:
        return {
          ...state,
          totalpages: action.payload,
        }
        case FETCH_USER_PERMISSION:
        return {
          ...state,
          userpermission: action.payload,
        }
        case FETCH_PERMISSIONS:
        return {
          ...state,
          allpermissions: action.payload,
        }
        case FETCH_ROLES:
          return {
            ...state,
            roles: action.payload,
          }
          case FETCH_ALLROLL:
            return {
              ...state,
              allroles: action.payload,
            }
          
    default:
      return state
  }
}

export default permissionReducer
