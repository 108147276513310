import { FETCH_DEPT } from './actionType'

const initialState = {
  deptment: {},
  // profileData: {},
  // userLocation: DEFAULT_DATA
}
const deptReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DEPT:
      return {
        ...state,
        deptment: action.payload,
      };
    
    // case USER_PROFILE:
    //     return {
    //         ...state,
    //         profileData: action.payload
    //     };
    // case USER_LOCATION:
    //     return {
    //         ...state,
    //         userLocation: action.payload
    //     };
    default:
      return state
  }
}

export default deptReducer
