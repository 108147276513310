import { FETCH_DEG, FETCH_EMP, FETCH_SITE_EMP } from './actionType'

const initialState = {
  emplyees: {},
  sitespecific:{},
  // userLocation: DEFAULT_DATA
}
const empReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EMP:
      return {
        ...state,
        emplyees: action.payload,
      }
      case FETCH_SITE_EMP:
      return {
        ...state,
        sitespecific: action.payload,
      }
    // case USER_PROFILE:
    //     return {
    //         ...state,
    //         profileData: action.payload
    //     };
    // case USER_LOCATION:
    //     return {
    //         ...state,
    //         userLocation: action.payload
    //     };
    default:
      return state
  }
}

export default empReducer
