import { FETCH_SITE_ATTENDANCE } from "./actionType";

const initialState = {
  siteAttendance: {},
  // profileData: {},
  // userLocation: DEFAULT_DATA
};
const siteAttendanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SITE_ATTENDANCE:
      return {
        ...state,
        siteAttendance: action.payload,
      };
    // case USER_PROFILE:
    //     return {
    //         ...state,
    //         profileData: action.payload
    //     };
    // case USER_LOCATION:
    //     return {
    //         ...state,
    //         userLocation: action.payload
    //     };
    default:
      return state;
  }
};

export default siteAttendanceReducer;
