import { FETCH_SECOND_TOKEN, FETCH_TOKEN } from './actionType'

const initialState = {
  token: {},
  user:{}
  // profileData: {},
  // userLocation: DEFAULT_DATA
}
// console.log('hiii', initialState.token)
const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TOKEN:
      // console.log('Current state:', state)
      // console.log('Action payload:', action.payload)
      return {
        ...state,
        token: action.payload,
      }
      case FETCH_SECOND_TOKEN:
      // console.log('Current state:', state)
      // console.log('Second payload:', action.payload)
      return {
        ...state,
        user: action.payload,
      }
    // case USER_PROFILE:
    //     return {
    //         ...state,
    //         profileData: action.payload
    //     };
    // case USER_LOCATION:
    //     return {
    //         ...state,
    //         userLocation: action.payload
    //     };
    default:
      return state
  }
}

export default usersReducer
