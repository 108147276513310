import { FETCH_CIRCUIT, FETCH_DEG, FETCH_EMP, FETCH_SIFT } from './actionType'

const initialState = {
  shift: {},
  circuit: {},
  // userLocation: DEFAULT_DATA
}
const shiftreducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SIFT:
      return {
        ...state,
        shift: action.payload,
      }
    case FETCH_CIRCUIT:
        return {
            ...state,
            circuit: action.payload
        };
    // case USER_LOCATION:
    //     return {
    //         ...state,
    //         userLocation: action.payload
    //     };
    default:
      return state
  }
}

export default shiftreducer
