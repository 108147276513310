import {
  FETCH_AVAILABLE_STOCK,
  FETCH_BARCODE,
  FETCH_BATCH,
  FETCH_COMMON_SITE,
  FETCH_DAILY_PRODUCTION,
  FETCH_DEPARTMENT_REPORTS,
  FETCH_DOSEING_REPORTS,
  FETCH_INDENT_LIST,
  FETCH_INDENT_REPORTS,
  FETCH_INDENT_UNIQ_LIST,
  FETCH_INTRANSIT_STOCK,
  FETCH_ISSUE_APPROVAL,
  FETCH_ISSUE_REPORTS,
  FETCH_MATERIAL,
  FETCH_MATERIAL_TYPE,
  FETCH_SITEDEPARTMENT,
  FETCH_SITE_DOZING,
  FETCH_WAREHOUSE,
  FETCH_WAREHOUSE_REPORTS,
  GET_INDENT_LIST,
  GET_MACHINERIES,
} from "./actionType";

const initialState = {
  materialtype: {},
  material: {},
  dailyproduction: {},
  indentlist: {},
  indentuniqlist: {},
  batch: {},
  warehouse: {},
  sitedepartment: {},
  sitedozing: {},
  commonsite: {},
  barcodedata: {},
  indentviewlist: {},
  indentregisterreport: {},
  issueregisterreport: {},
  avilablestock: {},
  intransitstock: {},
  warehousereports: {},
  departmentreports: {},
  dosingreports: {},
  issueapproval: {},
  machineries:{}
};
const inventoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MATERIAL_TYPE:
      return {
        ...state,
        materialtype: action.payload,
      };
    case FETCH_MATERIAL:
      return {
        ...state,
        material: action.payload,
      };
    case FETCH_DAILY_PRODUCTION:
      return {
        ...state,
        dailyproduction: action.payload,
      };
    case FETCH_INDENT_LIST:
      return {
        ...state,
        indentlist: action.payload,
      };
    case FETCH_INDENT_UNIQ_LIST:
      return {
        ...state,
        indentuniqlist: action.payload,
      };
    case FETCH_BATCH:
      return {
        ...state,
        batch: action.payload,
      };
    case FETCH_WAREHOUSE:
      return {
        ...state,
        warehouse: action.payload,
      };
    case FETCH_SITEDEPARTMENT:
      return {
        ...state,
        sitedepartment: action.payload,
      };
    case FETCH_SITE_DOZING:
      return {
        ...state,
        sitedozing: action.payload,
      };
    case FETCH_COMMON_SITE:
      return {
        ...state,
        commonsite: action.payload,
      };
    case FETCH_BARCODE:
      return {
        ...state,
        barcodedata: action.payload,
      };
    case GET_INDENT_LIST:
      return {
        ...state,
        indentviewlist: action.payload,
      };
    case FETCH_INDENT_REPORTS:
      return {
        ...state,
        indentregisterreport: action.payload,
      };
    case FETCH_ISSUE_REPORTS:
      return {
        ...state,
        issueregisterreport: action.payload,
      };
    case FETCH_AVAILABLE_STOCK:
      return {
        ...state,
        avilablestock: action.payload,
      };
    case FETCH_INTRANSIT_STOCK:
      return {
        ...state,
        intransitstock: action.payload,
      };
    case FETCH_WAREHOUSE_REPORTS:
      return {
        ...state,
        warehousereports: action.payload,
      };
    case FETCH_DEPARTMENT_REPORTS:
      return {
        ...state,
        departmentreports: action.payload,
      };
    case FETCH_DOSEING_REPORTS:
      return {
        ...state,
        dosingreports: action.payload,
      };
      case FETCH_ISSUE_APPROVAL:
        return {
          ...state,
          issueapproval: action.payload,
        };
        case GET_MACHINERIES:
        return {
          ...state,
          machineries: action.payload,
        };
    default:
      return state;
  }
};

export default inventoryReducer;
