export const FETCH_MATERIAL_TYPE='views/inventory/FETCH_MATERIAL_TYPE';
export const FETCH_MATERIAL='views/inventory/FETCH_MATERIAL';
export const FETCH_DAILY_PRODUCTION='views/inventory/FETCH_DAILY_PRODUCTION';
export const FETCH_INDENT_LIST = 'views/inventory/FETCH_INDENT_LIST';
export const FETCH_INDENT_UNIQ_LIST = 'views/inventory/FETCH_INDENT_UNIQ_LIST';
export const FETCH_BATCH = 'views/inventory/FETCH_BATCH';
export const FETCH_WAREHOUSE = "views/inventory/FETCH_WAREHOUSE";
export const FETCH_SITEDEPARTMENT = "views/inventory/FETCH_SITEDEPARTMENT";
export const FETCH_SITE_DOZING = "views/inventory/FETCH_SITE_DOZING"
export const FETCH_COMMON_SITE = "views/inventory/FETCH_COMMON_SITE"
export const FETCH_BARCODE = "views/inventory/FETCH_BARCODE"
export const GET_INDENT_LIST = "views/inventory/GET_INDENT_LIST"
export const FETCH_INDENT_REPORTS = "views/inventory/FETCH_INDENT_REPORTS"
export const FETCH_ISSUE_REPORTS = "views/inventory/FETCH_ISSUE_REPORTS"
export const FETCH_AVAILABLE_STOCK = "views/inventory/FETCH_AVAILABLE_STOCK"
export const FETCH_INTRANSIT_STOCK = "views/inventory/FETCH_INTRANSIT_STOCK"
export const FETCH_WAREHOUSE_REPORTS = "views/inventory/FETCH_WAREHOUSE_REPORTS"
export const FETCH_DEPARTMENT_REPORTS = "views/inventory/FETCH_DEPARTMENT_REPORTS"
export const FETCH_DOSEING_REPORTS = "views/inventory/FETCH_DOSEING_REPORTS"
export const FETCH_ISSUE_APPROVAL = "views/inventory/FETCH_ISSUE_APPROVAL"
export const GET_MACHINERIES = "views/inventory/GET_MACHINERIES"



