import { FETCH_DEG } from './actionType'

const initialState = {
  designation: {},
  // profileData: {},
  // userLocation: DEFAULT_DATA
}
const degReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DEG:
      return {
        ...state,
        designation: action.payload,
      }
    // case USER_PROFILE:
    //     return {
    //         ...state,
    //         profileData: action.payload
    //     };
    // case USER_LOCATION:
    //     return {
    //         ...state,
    //         userLocation: action.payload
    //     };
    default:
      return state
  }
}

export default degReducer
